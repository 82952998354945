/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { RadioButton } from "primereact/radiobutton";
import { useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";
import clsx from "clsx";
import moment from "moment";
import supabase from "../../api/supabase";

import { ClientForm } from "../Clients";
import { ContactForm } from "../Contacts";
import { Input, TextArea, Dropdown, Address, QuoteTab, Button, Spinner } from "../../common";
import { CreateFile } from "../Staff/CreateFile";
import { QuoteLines } from "./QuoteLines";
import { Rates } from "./Rates/Rates";
import { AdminRates } from "./Rates/AdminRates";
import { TickBox } from "../../common/TickBox";

import { AdditionalItems } from "./AdditionalItems/AdditionalItems";
import { AdditionalConditionsQuote } from "./AdditionalConditionsQuote/AdditionalConditionsQuote";

import { Totals } from "./QuoteTotals";
import { useClients } from "../../api/Clients";
import { useStaff } from "../../api/Staff";
import { useJobs } from "../../api/Jobs";
import { useFetchQuoteImages } from "../../api/Quotes";

import { fetchAllContacts } from "../../api/ClientContacts";
import { QuotesApi, WeeklyHireApi, JobsApi, StaffApi, FilesApi } from "../../api";

import { useNotificationStore } from "../../store/notifications";
import { QuotePhotos } from "./QuotePhotos";

import {
  formatAddons,
  formatImages,
  formatQuoteLines,
  formatQuotePortalLines,
  formatPortalAddons,
  formatQuoteAdditionalConditions,
  formatZones,
  formatRates,
  tabs,
  zoneOptions,
  quoteRates,
  quoteTerms,
} from "./utils";

export const quoteTypeOptions = [
  { value: "New", label: "New" },
  { value: "Variation", label: "Variation" },
  { value: "Contract", label: "Contract" },
];

export const clientTypeOptions = [
  { value: "Commercial", label: "Commercial" },
  { value: "Construction", label: "Construction" },
  { value: "Civil", label: "Civil" },
  { value: "Residential", label: "Residential" },
];

const brandingOptions = [{ value: "MetroScaff", label: "MetroScaff" }];

const estimatedWayOptions = [
  { value: "Meters", label: "Meters" },
  { value: "Total ED", label: "Total ED" },
];

const termsWayOptions = [
  { value: "100", label: "100" },
  { value: "50", label: "50" },
  { value: "10", label: "10" },
];

export function QuoteForm({ formType = "create" }) {
  const history = useHistory();
  const [redirectOutput, setRedirectOutput] = useState(false);
  const [quoteInitialized, setQuoteInitialized] = useState(false);
  const { addNotification } = useNotificationStore();
  const [sharedCheckBoxesState, setsharedCheckBoxesState] = useState([]);

  const { user } = supabase.auth.currentSession;
  // console.log(JSON.stringify(supabase.auth));

  const [loggedUser, setLoggedUser] = useState(null);

  // Client Form
  const [clientForm, setClientForm] = useState(false);
  const [clientId, setClientId] = useState(null);

  // Contact Form
  const [contactForm, setContactForm] = useState(false);
  const [contactId, setContactId] = useState(null);

  const [transportRate, setTransportRate] = useState(0);
  const [fetchedQuote, setFetchedQuote] = useState([]);
  const [quoteCreating, setQuoteCreating] = useState(false);

  // Quote Lines
  const [estimatedWay, setEstimatedWay] = useState(null);
  const [termsWay, setTermsWay] = useState(null);
  const [quoteLines, setQuoteLines] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  const [termValues, setTermValues] = useState({
    standardRate: 70,
    nonStandardRate: 100,
    smallTruck: 200,
    hiabTruck: 300,
  });

  // const { data, error } = supabase.auth.update({
  //   data: { permissions: ["rates_admin"]}
  // })
  const tabsForQuote = [];
  tabs.forEach((tab) => {
    if (tab.needs_admin) {
      if (
        user.user_metadata.permissions !== null &&
        user.user_metadata.permissions?.includes("rates_admin")
      ) {
        tabsForQuote.push(tab);
      }
    } else {
      tabsForQuote.push(tab);
    }
  });

  
  // Quote Phases
  const [zoneValues, setZoneValues] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([{ id: 1, label: "Scaffold", zone_id: "" }]);
  const [zones, setZones] = useState(null);
  
  // Totals
  const [weekTotal, setWeekTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [transportTotal, setTransportTotal] = useState(0);
  const [erectDismantleTotal, setErectDismantleTotal] = useState(0);
  const [additionalTotal, setAdditionalTotal] = useState(0);
  
  // Curent Quote Tab
  const [currentTab, setCurrentTab] = useState("Phases");
  
  // console.log(quoteNum);
  const [client, setClient] = useState(null);
  const [clientObject, setClientObject] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [rates, setRates] = useState([]);
  const clientData = useClients();
  const staffData = useStaff();
  const jobData = useJobs();
  const { data: defaultEstimatorRecord } = StaffApi.useStaffByEmail(user?.email);
  const { quoteId } = useParams();
  const { data: quotePhotosData } = useFetchQuoteImages(quoteId);
  
  useEffect(async () => {
    if (formType === "create") {
      const ratesData = await QuotesApi.fetchRates();
      const ratesDataNoDates = ratesData.map((rate) => {
        delete rate.created_at;
        delete rate.updated_at;
        return rate;
      });
      setRates(ratesDataNoDates);
      const additionalConditions = await QuotesApi.fetchAdditionalConditions();
      setsharedCheckBoxesState(additionalConditions);
    }
    
    // // Generate next quote #
    // const lastQuoteNum = await QuotesApi.getQuoteNum();
    // const nextNumSeq = lastQuoteNum ? lastQuoteNum : 1000;
    // setQuoteCreateID(nextNumSeq);
    // setQuoteNum(nextNumSeq);
    handleZoneChange(1);
  }, []);
  
  useEffect(() => {
    if(rates && rates.length > 0 && !quoteInitialized) {
      const inspectionRate = rates.find((rate) => rate.service.includes('Inspection Charge'));
      const additionalItemRow = {
        description: "Weekly Inspections",
        duration: "1",
        fixedCharge: inspectionRate?.erect_fee,
        id: null,
        totalCost: inspectionRate?.erect_fee,
        type: "INSP - Inspection Charge",
      }
      setAdditionalItems([...additionalItems, additionalItemRow]);
      setQuoteInitialized(true);
    }
  }, [rates]);

  useEffect(() => {
    renderClientList();
  }, [clientId]);
  
  useEffect(async () => {
    if (contactId && clientId) {
      const contactsRes = await fetchAllContacts(clientId);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [contactId]);

  useEffect(async () => {
    let isCurrent = true;

    if (quoteId) {
      const quote = await QuotesApi.fetchQuote(quoteId);
      setZones(quote?.quote_zones?.length);

      if (isCurrent) {
        const contactsRes = await fetchAllContacts(quote?.client);
        let contacts;
        if (contactsRes && contactsRes.length > 0) {
          contacts = contactsRes.map((contact) => ({
            label: contact.name,
            value: contact.id,
          }));
        } else {
          contacts = [];
        }
        setsharedCheckBoxesState(quote?.quote_additional_conditions);
        setContactList(contacts);
        setFetchedQuote(quote);
        setRates(quote.quote_rates);

        const formatQuoteLines = formatQuotePortalLines(quote?.quote_lines);
        const formQuoteAddons = formatPortalAddons(quote?.quote_addons);
        const zoneLineItems = zoneOptions.slice(0, quote?.quote_zones.length);

        const labelsArr = quote?.quote_zones.map((item, index) => ({
          zone_id: item.id,
          id: item.zone_id,
          label: String(item.zone_label).trim(),
        }));

        setZoneLabels(labelsArr);
        setEstimatedWay(quote.estimatedWay);
        setZoneValues(zoneLineItems);
        setQuoteLines(formatQuoteLines);
        setAdditionalItems(formQuoteAddons);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [quoteId]);

  useEffect(async () => {
    if (client) {
      const contactsRes = await fetchAllContacts(client);
      let contacts;
      if (contactsRes && contactsRes.length > 0) {
        contacts = contactsRes.map((contact) => ({
          label: contact.name,
          value: contact.id,
        }));
      } else {
        contacts = [];
      }
      setContactList(contacts);
    }
  }, [client]);

  useEffect(() => {
    let totalWeek = 0;
    let totalAmount = 0;
    let totalAdditionalItems = 0;
    let totalErectDismantle = 0;

    if (quoteLines.length) {
      totalWeek += quoteLines.reduce((acc, curr) => acc + Number(curr.hireFee), 0);
      totalErectDismantle += quoteLines.reduce((acc, curr) => acc + Number(curr.dismantle), 0);
    }
    if (additionalItems.length) {
      totalAdditionalItems += additionalItems.reduce(
        (acc, curr) => acc + Number(curr.totalCost),
        0,
      );
    }

    totalAmount += totalErectDismantle + totalAdditionalItems + totalWeek;

    setTotal(totalAmount);
    setWeekTotal(totalWeek);
    setErectDismantleTotal(totalErectDismantle);
    setAdditionalTotal(totalAdditionalItems);
  }, [quoteLines, additionalItems, erectDismantleTotal, additionalTotal]);

  // MUTATIONS
  const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const updateZonesMutation = QuotesApi.useUpdateZones();
  const updateLinesMutation = QuotesApi.useUpdateLines();
  const updateAddonsMutation = QuotesApi.useUpdateAddons();
  const updateRatesMutation = QuotesApi.useUpdateRates();
  const updateAdditionalConditions = QuotesApi.useUpdateAdditionalConditions();
  const deleteQuoteZoneMutation = QuotesApi.useDeleteQuoteZone();
  const updateImagesMutation = QuotesApi.useUpdateImages();

  const createJobFromQuote = QuotesApi.useCreateJobFromQuote();
  const createJobTasksFromQuote = QuotesApi.useCreateJobTasksFromQuote();
  const createHireInvoices = WeeklyHireApi.useCreateHire();
  const createEdInvoiceMutation = JobsApi.useCreateEdInvoice();

  const createQuoteMutation = QuotesApi.useCreateQuote();
  const createZonesMutation = QuotesApi.useCreateZones();
  const createLinesMutation = QuotesApi.useCreateLines();
  const createAddonsMutation = QuotesApi.useCreateAddons();
  const createRatesMutation = QuotesApi.useCreateRates();
  const createFileMutation = FilesApi.useCreateFile();
  const createAdditionalConditions = QuotesApi.useCreateAdditionalConditions();
  const createImagesMutation = QuotesApi.useCreateImages();

  const renderClientList = () => {
    if (clientData && clientData?.data?.length > 0) {
      return clientData.data.map((client) => ({
        label: client.client_name,
        value: client.id,
      }));
    }
    return [];
  };

  const getClientFromId = (client_id) => {
    if (clientData && clientData?.data?.length > 0) {
      const client = clientData.data.find((client) => client.id === client_id);
      if (client) {
        return client;
      }
    }
    return null;
  };

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data
        .filter(
          (staff) =>
            staff.staff_name === "Ash Nolan" ||
            staff.staff_name === "Paul Nolan" ||
            staff.staff_name === "Logan Caldwell",
        )
        .map((staff) => ({
          label: staff.staff_name,
          value: staff.id,
          email: staff.email,
        }));
    }
    return [];
  };

  const renderJobList = () => {
    if (jobData && jobData?.data?.length > 0) {
      return jobData.data.map((job) => ({
        label: `${job.id} - ${job.site}`,
        value: job.id,
      }));
    }
    return [];
  };

  const renderContactList = () => {};

  const handleZoneChange = (val) => {
    const zoneVal = Number(val);

    const labelsArr = Array(zoneVal)
      .fill()
      .map((obj, index) => ({
        zone_id: zoneLabels[index]?.zone_id || "",
        id: index + 1,
        label: zoneLabels[index]?.label || "",
      }));
    setZoneLabels(labelsArr);
    setZones(val);

    const zoneLineItems = zoneOptions.slice(0, zoneVal);
    setZoneValues(zoneLineItems);
  };

  const handleZoneLabelChange = (val, index) => {
    setZoneLabels(
      zoneLabels.map((item) => {
        if (index === Number(item.id)) {
          return { ...item, label: val.target.value };
        }
        return item;
      }),
    );
  };

  if (quoteId && !fetchedQuote.id) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div className="mb-10">
      <div className="">
        <Formik
          initialValues={{
            quote_type: "New",
            PO_Number: "",
            variation_job_id: fetchedQuote.variation_job_id || "",
            quote_num: formType === "edit" ? `${fetchedQuote.quote_num}` : ``,
            branding: fetchedQuote.branding || "MetroScaff",
            clientType: fetchedQuote.clientType || "Residential",
            client: fetchedQuote.client || clientId,
            version: fetchedQuote?.version || 1,
            estimator:
              fetchedQuote?.estimator ||
              (defaultEstimatorRecord?.id ? defaultEstimatorRecord?.id : null),
            contact: fetchedQuote.contact_id || contactId,
            maxZones: fetchedQuote.max_zones ? String(fetchedQuote.max_zones) : "1",
            description: fetchedQuote.description || "",
            street: fetchedQuote?.street_1 || "",
            street2: fetchedQuote?.street_2 || "",
            city: fetchedQuote?.city || "",
            postal: fetchedQuote.post_code || "",
            transport_total: fetchedQuote || transportRate,
            terms: fetchedQuote?.terms || `${quoteTerms}`,
            status: fetchedQuote?.status || "Pending",
            estimatedWay: fetchedQuote?.estimatedWay || null,
            suburb: fetchedQuote?.suburb || "",
            photo: fetchedQuote?.photo || "",
            scafflog_number: fetchedQuote?.scafflog_number || "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.quote_type === "Variation" && !values.variation_job_id) {
              errors.variation_job_id = "Job is required.";
            }
            if (!values.estimatedWay) {
              errors.estimatedWay = "How will this job be estimated? is required";
            }
            // console.log(values?.quote_photo_1, 'quotephoto1')
            /*
            if (!values.quote_num) {
              errors.quote_num = "Quote # is required.";
            }
                      
            */
            // console.log("Validating");
            if (!values.client) {
              errors.client = "Client is required.";
            }

            if (
              clientObject?.enforce_purchase_order === true &&
              (!values.PO_Number || values.PO_Number === "")
            ) {
              errors.PO_Number = "This client requires PO Numbers.";
            }

            if (!values.estimator) {
              errors.estimator = "Estimator is required.";
            }
            const zoneEmpty = zoneLabels.find((e) => e.label === "");

            if (zoneEmpty !== undefined) {
              errors.zoneLabels = "Is required.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.time("CREATING");
            setQuoteCreating(true);
            const {
              quote_type,
              PO_Number,
              variation_job_id,
              quote_num,
              client,
              version,
              contact,
              estimator,
              clientType,
              maxZones,
              description,
              street,
              street2,
              city,
              postal,
              terms,
              status,
              estimatedWay,
              suburb,
              photo,
              scafflog_number
            } = values;
            const quotePayload = {
              quote_type,
              PO_Number,
              variation_job_id: variation_job_id || null,
              quote_num,
              version,
              clientType,
              branding: values.branding || "",
              client: client || null,
              contact_id: contact || null,
              estimator: estimator || null,
              created_by: user?.user_metadata?.name,
              max_zones: maxZones,
              description,
              street_1: street,
              street_2: street2,
              city,
              post_code: postal,
              terms: String(terms),
              transport_total: Number(transportTotal),
              weekly_total: Number(weekTotal) || null,
              total_amount: Number(total) || null,
              status,
              estimatedWay,
              additionalTotal: Number(additionalTotal),
              erectDismantleTotal: Number(erectDismantleTotal),
              suburb,
              photo,
              scafflog_number,
            };

            if (formType === "edit") {
              quotePayload.status =
                values.quote_type === "Contract" ? "Approved" : quotePayload.status;
              try {
                console.time("TIMER");
                const quoteResult = await updateQuoteMutation.mutateAsync(
                  {
                    quote: quotePayload,
                    quoteId: fetchedQuote?.id,
                  },
                  {
                    onSuccess: (payload) => {
                      // console.log(quotePayload, "quotePayload");
                      const zones = formatZones(zoneLabels, fetchedQuote?.id, "edit");
                      // console.log(zones, "zones");
                      const lines = formatQuoteLines(quoteLines, fetchedQuote?.id, "edit").filter(
                        (line) => Number(line.zone) <= Number(maxZones),
                      );
                      const addons = formatAddons(additionalItems, fetchedQuote?.id, "edit");
                      const formatedRates = formatRates(rates, fetchedQuote?.id, "edit");
                      const formatedImages = formatImages(values, quoteId);
                      const formatedQuoteAdditionalConditions = formatQuoteAdditionalConditions(
                        sharedCheckBoxesState,
                        quoteId,
                        "edit",
                      );
                      const deletedNumber = maxZones - fetchedQuote?.quote_zones?.length;
                      if (deletedNumber < 0) {
                        const deletedZones = fetchedQuote?.quote_zones
                          .slice(deletedNumber)
                          .map((item) => item.id);
                        deleteQuoteZoneMutation.mutate(deletedZones, {
                          onSuccess: (payload) => {},
                          onError: (error) => console.log("error", error),
                        });
                      }

                      updateZonesMutation.mutate(zones, {
                        onSuccess: (payload) => {},
                        onError: (error) => console.log("error", error),
                      });

                      updateLinesMutation.mutate(lines, {
                        onSuccess: (payload) => {},
                        onError: (error) => console.log("error", error),
                      });

                      updateRatesMutation.mutate(formatedRates, {
                        onError: (error) => console.log("error", error),
                      });

                      updateImagesMutation.mutate(formatedImages, {
                        onError: (error) => console.log("error", error),
                      });

                      updateAdditionalConditions.mutate(formatedQuoteAdditionalConditions, {
                        onError: (error) => console.log("error", error),
                      });

                      updateAddonsMutation.mutate(addons, {
                        onSuccess: (payload) => {
                          setQuoteCreating(false);

                          if (redirectOutput) {
                            history.push(`/quotes/${fetchedQuote?.id}/output`);
                          } else {
                            history.push("/quotes");
                          }
                          addNotification({
                            isSuccess: true,
                            heading: "Success!",
                            content: `Successfully updated quote!`,
                          });
                        },
                        onError: (error) => {
                          setQuoteCreating(false);

                          history.push("/quotes");
                          addNotification({
                            isSuccess: false,
                            heading: "Failure!",
                            content: `Failed to update quote. ${error?.message}`,
                          });
                        },
                      });
                    },
                    onError: (error) => console.log("error", error),
                  },
                );
              } catch (err) {
                console.log("error", err);
              }
              if (formType === "edit" && values.quote_type === "Contract") {
                const fields = [
                  [quotePayload.street_1],
                  [quotePayload.street_2],
                  [quotePayload.city],
                ];
                const addressFormat = fields
                  .map((part) => part.filter(Boolean).join(" "))
                  .filter((str) => str.length)
                  .join(", ");
                const jobPayload = {
                  id: Number(quotePayload?.id),
                  client_id: quotePayload?.client,
                  site: addressFormat || "",
                  quote_id: quoteId || null,
                  start_date: moment().format("DD/MM/YYYY"),
                  end_date: moment().add(3, "M").format("DD/MM/YYYY"),
                  job_status: "Pending Job Confirmation Doc",
                  status: "Active",
                  clientType: quotePayload?.clientType,
                  branding: quotePayload?.branding,
                  job_num: Number(quote_num),
                };
                const createdJob = await createJobFromQuote.mutateAsync({jobPayload});

                const quoteLines = quotePayload?.quote_lines;
                const quoteAddons = quotePayload?.quote_addons;
                const formatedImages = formatImages(values, quoteId);
                console.log(quoteLines);
                if (quoteLines?.length && createdJob?.id) {
                  const formatTaskPayload = quoteLinesToJobTasks(quoteLines, createdJob.id);

                  const taskCreated = await createJobTasksFromQuote.mutateAsync({
                    formatTaskPayload,
                    quote_num,
                  });

                  const formatInvoices = quoteLinesToWeeklyInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                  );

                  const formatEDInvoices = quoteLinesToEDInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                    quote_num,
                  );
                  await createHireInvoices.mutateAsync(formatInvoices);
                  await createEdInvoiceMutation.mutateAsync(formatEDInvoices);
                }
                const jobFiles = formatedImages.map((image, index) => ({
                  file_type: "Quote Attachment",
                  file_description: `Quote Attachment ${index}`,
                  file: image.link,
                  notes: image.comment,
                  job_id: createdJob.id,
                }));
                console.warn(jobFiles);
                await createFileMutation.mutateAsync(jobFiles);
              }
            } else {
              if (values.quote_type === "Contract") {
                const fields = [
                  [quotePayload.street_1],
                  [quotePayload.street_2],
                  [quotePayload.city],
                ];
                const addressFormat = fields
                  .map((part) => part.filter(Boolean).join(" "))
                  .filter((str) => str.length)
                  .join(", ");
                const jobPayload = {
                  id: Number(quote_num),
                  client_id: quotePayload?.client,
                  site: quotePayload.street_1 || "",
                  quote_id: quoteId || null,
                  start_date: moment().format("DD/MM/YYYY"),
                  end_date: moment().add(3, "M").format("DD/MM/YYYY"),
                  job_status: "Pending Job Confirmation Doc",
                  status: "Active",
                  clientType: quotePayload?.clientType,
                  branding: quotePayload?.branding,
                  job_num: Number(quote_num),
                  scafflog_number
                };
                quotePayload.status =
                  values.quote_type === "Contract" ? "Approved" : quotePayload.status;
                const createdJob = await createJobFromQuote.mutateAsync({...jobPayload, id: quotePayload.id});
                const quoteAddons = additionalItems;
                if (quoteLines?.length && createdJob?.id) {
                  const formatTaskPayload = quoteLinesToJobTasks(quoteLines, createdJob.id);

                  const taskCreated = await createJobTasksFromQuote.mutateAsync({
                    formatTaskPayload,
                    quote_num,
                  });

                  const formatInvoices = quoteLinesToWeeklyInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                  );

                  const formatEDInvoices = quoteLinesToEDInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                    quote_num,
                  );

                  await createHireInvoices.mutateAsync(formatInvoices);

                  await createEdInvoiceMutation.mutateAsync(formatEDInvoices);
                }
                const formatedImages = formatImages(values, quoteId);
                const jobFiles = formatedImages.map((image, index) => ({
                  file_type: "Quote Attachment",
                  file_description: `Quote Attachment ${index}`,
                  file: image.link,
                  notes: image.comment,
                  job_id: createdJob.id,
                }));
                await createFileMutation.mutateAsync(jobFiles);

                if (quoteAddons?.length && createdJob?.id) {
                  const formatedinvoices = quoteAddonsToEdInvoices(quoteAddons, createdJob?.id);

                  await createEdInvoiceMutation.mutateAsync(formatedinvoices);
                }
              }
              // console.log(quotePayload, 'quotePayload')
              createQuoteMutation.mutate({...quotePayload}, {
                onSuccess: (payload) => {
                  const quoteId = payload?.[0]?.id;

                  // Format payloads
                  const zones = formatZones(zoneLabels, quoteId);
                  const addons = formatAddons(additionalItems, quoteId);
                  const lines = formatQuoteLines(quoteLines, quoteId);
                  const formatedRates = formatRates(rates, quoteId);
                  const formatedQuoteAdditionalConditions = formatQuoteAdditionalConditions(
                    sharedCheckBoxesState,
                    quoteId,
                  );
                  const formatedImages = formatImages(values, quoteId);

                  // CREATE ZONES
                  createZonesMutation.mutate(zones, {
                    onError: (error) => console.log("error", error),
                  });

                  // CREATE ZONES
                  createLinesMutation.mutate(lines, {
                    onError: (error) => console.log("error", error),
                  });

                  createRatesMutation.mutate(formatedRates, {
                    onError: (error) => console.log("error", error),
                  });
                  createAdditionalConditions.mutate(formatedQuoteAdditionalConditions, {
                    onError: (error) => console.log("error", error),
                  });

                  // CREATE QUOTE IMAGES
                  // console.log("Imagenes: ", formatedImages);
                  createImagesMutation.mutate(formatedImages, {
                    onError: (error) => console.log("error", error),
                    onSuccess: () => {},
                  });
                  // CREATE QUOTE ADDONS
                  createAddonsMutation.mutate(addons, {
                    onSuccess: (payload) => {
                      setQuoteCreating(false);

                      history.push(`/quotes/${quoteId}/details`);
                      addNotification({
                        isSuccess: true,
                        heading: "Success!",
                        content: `Successfully created quote!`,
                      });
                    },
                    onError: (err) => {
                      setQuoteCreating(false);

                      // history.push("/quotes");
                      addNotification({
                        isSuccess: false,
                        heading: "Failure!",
                        content: `Failed to create quote. ${err?.message}`,
                      });
                    },
                  });
                },
                onError: (err) => {
                  setQuoteCreating(false);
                },
              });
            }
            console.timeEnd("CREATING");
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            validateForm,
          }) => (
            <div className="w-full">
              <div className="flex">
                <form onSubmit={handleSubmit} id="quoteForm" className="w-4/6">
                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="New / Variation / Contract"
                        id="quote_type"
                        value={values.quote_type}
                        onChange={(opt, value) => {
                          setFieldValue("quote_type", value);
                          // setClientId(value);
                          // setClient(value);
                        }}
                        onBlur={setFieldTouched}
                        options={quoteTypeOptions}
                      />
                    </div>
                  </div>

                  {values.quote_type === "Variation" && (
                    <div className="flex">
                      <div className="w-1/2">
                        <Dropdown
                          label="Job"
                          id="variation_job_id"
                          value={values.variation_job_id}
                          onChange={async (opt, value) => {
                            setFieldValue("variation_job_id", value);

                            // reset field values
                            setFieldValue("client", "");
                            setFieldValue("contact", "");
                            setFieldValue("street", "");
                            setFieldValue("street2", "");
                            setFieldValue("city", "");
                            setFieldValue("postal", "");
                            // Find matching job and quote
                            const jobMatch = jobData.data.find((job) => job.id === value);

                            if (jobMatch && jobMatch.quote_id) {
                              try {
                                const { data, error } = await supabase
                                  .from("quotes")
                                  .select("*")
                                  .eq("id", Number(jobMatch.quote_id));
                                const quote = data?.[0];
                                if (quote) {
                                  const oldQuoteNumber = quote?.quote_num?.split("-");
                                  let { data } = await supabase.from("quotes");
                                  data = data.map((row) => row.quote_num.split("-"));
                                  data = data.filter((e) => oldQuoteNumber[0] === e[0]);

                                  setFieldValue("client", quote?.client);
                                  setFieldValue(
                                    "quote_num",
                                    `${oldQuoteNumber[0]}-V${data.length}`,
                                  );
                                  setClientId(quote?.client);
                                  setClient(quote?.client);
                                  setFieldValue("clientType", quote?.clientType || "");
                                  setFieldValue("maxZones", String(quote?.max_zones) || "");
                                  setFieldValue("contact", quote?.contact_id || "");
                                  setFieldValue("street", quote?.street_1 || "");
                                  setFieldValue("street2", quote?.street_2 || "");
                                  setFieldValue("city", quote?.city || "");
                                  setFieldValue("postal", quote?.post_code || "");
                                  setFieldValue("estimator", quote?.estimator || "");
                                }
                              } catch (err) {
                                console.log("error", err);
                              }
                            }

                            // setClientId(value);
                            // setClient(value);
                          }}
                          error={errors.variation_job_id}
                          onBlur={setFieldTouched}
                          options={renderJobList()}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <div className="w-1/2">
                      <Dropdown
                        label="Client"
                        id="client"
                        value={values.client}
                        onChange={(opt, value) => {
                          setFieldValue("client", value);
                          setClientId(value);
                          setClient(value);
                          setClientObject(getClientFromId(value));
                        }}
                        onBlur={setFieldTouched}
                        isLoading={clientData.isFetching}
                        options={renderClientList()}
                        error={errors.client}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon className="w-6 h-6 text-indigo-500" />
                          <button
                            type="button"
                            className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
                            onClick={() => setClientForm(true)}
                          >
                            Add New Client
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label="Phases"
                        id="maxZones"
                        value={values.maxZones}
                        onChange={(opt, value) => {
                          setFieldValue("maxZones", value);
                          handleZoneChange(value);
                        }}
                        // onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        options={zoneOptions}
                      />
                    </div>
                    <div className="w-1/2">
                      <Input
                        title="PO Number"
                        id="PO_Number"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.PO_Number}
                        error={errors.PO_Number}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    {(values.quote_num && values.quote_num.length > 0) ? (
                    <div className="w-1/2">
                      <Input
                        title="Quote #"
                        id="quote_num"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.quote_num}
                        disabled
                        // error={errors.quote_num}
                      />
                    </div> ) : (null)
                    }
                    <div className="w-1/2">
                      <Input
                        title="Scafflog #"
                        id="scafflog_number"
                        type="text"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.scafflog_number}
                        // error={errors.quote_num}
                      />
                    </div>

                    <div className="w-1/2">
                      <Dropdown
                        label="Contact"
                        id="contact"
                        value={values.contact}
                        onChange={(opt, value) => {
                          setContactId(value);
                          setFieldValue("contact", value);
                        }}
                        onBlur={setFieldTouched}
                        options={contactList}
                      />
                      {formType !== "edit" && (
                        <div className="flex items-center pl-4">
                          <PlusCircleIcon
                            className={clsx(
                              !clientId ? "text-indigo-100" : "text-indigo-500",
                              "w-6 h-6",
                            )}
                          />
                          <button
                            type="button"
                            className={clsx(
                              !clientId
                                ? "text-gray-200 cursor-none"
                                : "text-gray-600 hover:text-gray-800",
                              "pl-1 font-semibold leading-5 text-sm",
                            )}
                            onClick={() => setContactForm(true)}
                            disabled={!clientId}
                          >
                            Add New Contact
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {values.quote_type !== "Contract" && (
                    <TextArea
                      title="Scope of Work"
                      id="description"
                      type="text"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                    />
                  )}
                  <div className="px-2">
                    <QuotePhotos
                      setFieldValue={setFieldValue}
                      data={formType !== "create" ? quotePhotosData : []}
                    />
                    <br />
                  </div>
                  <div className="w-1/2">
                    <Dropdown
                      label="Estimator"
                      id="estimator"
                      // filterByEmail={values.estimator === user?.email}
                      value={values.estimator}
                      onChange={(opt, value) => {
                        setFieldValue("estimator", value);
                      }}
                      onBlur={setFieldTouched}
                      isLoading={staffData.isFetching}
                      options={renderStaffList()}
                      error={errors.estimator}
                    />
                  </div>
                  <h3 className="text-lg px-4 leading-6 font-large">Site Address</h3>
                  <Address
                    fullAddress={values.fullAddress}
                    streetId="street"
                    streetId2="street2"
                    cityId="city"
                    postalId="postal"
                    suburbId="suburb"
                    streetVal={values.street}
                    street2Val={values.street2}
                    cityVal={values.city}
                    postalVal={values.postal}
                    suburbVal={values.suburb}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                  <h3 className="block text-sm font-medium text-gray-700 w-1/2 ml-4 mt-1">
                    How will this job be estimated?
                  </h3>
                  <div className="w-1/2 ml-4 mt-1 grid grid-cols-2 gap-2">
                    <div>
                      <RadioButton
                        inputId="howestimateMeters"
                        name="howestimateMeters"
                        value="Meters"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "Meters"}
                      />
                      <label htmlFor="howestimateMeters" className="p-radiobutton-label ml-1">
                        Meters
                      </label>
                    </div>

                    <div>
                      <RadioButton
                        inputId="howestimateTotalED"
                        name="howestimateTotalED"
                        value="Total ED"
                        onChange={(e) => {
                          setFieldValue("estimatedWay", e.target.value);
                          setEstimatedWay(e.target.value);
                        }}
                        checked={values.estimatedWay === "Total ED"}
                      />
                      <label htmlFor="howestimateTotalED" className="p-radiobutton-label ml-1">
                        Total ED
                      </label>
                    </div>
                  </div>
                </form>
                <div className="w-full">
                  <div className="w-4/6 mx-auto">
                    <div className="flex justify-start mb-4">
                      <QuoteTab tabs={tabsForQuote} handleChange={(tab) => setCurrentTab(tab)} />
                    </div>
                    {currentTab === "Phases" ? (
                      <>
                        {zones ? (
                          <div className="grid grid-cols-2">
                            {[...Array(Number(zones))].map((_, index) => (
                              <div className="flex items-center" key={index}>
                                <Input
                                  id={index + 1}
                                  title={`Phase ${index + 1}`}
                                  labelInline
                                  placeholder={`Phase ${index + 1}`}
                                  error={errors?.zoneLabels}
                                  defaultValue={
                                    formType === "edit"
                                      ? fetchedQuote?.quote_zones?.[index]?.zone_label
                                      : "Scaffold"
                                  }
                                  handleChange={(val) => handleZoneLabelChange(val, index + 1)}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <p>Select # of zones to continue</p>
                          </div>
                        )}
                      </>
                    ) : currentTab === "Rates Edit" ? (
                      <AdminRates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          // console.log("CHANGeD!!!! ", updatedTerms);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    ) : (
                      <Rates
                        data={rates}
                        setRates={setRates}
                        user={user}
                        handleChange={(data) => {
                          const updatedTerms = formatRateText(data);
                          // console.log("CHANGed!!!! ", updatedTerms);
                          setFieldValue("terms", updatedTerms);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {estimatedWay !== null && (
                <div className="px-4">
                  <QuoteLines
                    quoteLines={quoteLines}
                    setQuoteLines={setQuoteLines}
                    zoneOptions={zoneValues}
                    zoneLabels={zoneLabels}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                  <AdditionalItems
                    items={additionalItems}
                    setItems={setAdditionalItems}
                    rates={rates}
                    formType={formType}
                    estimatedWay={estimatedWay}
                  />
                </div>
              )}
              {values.quote_type !== "Contract_IGNORE_THIS_CHECK" && (
                <div className="flex">
                  <div className="w-1/2">
                    <br />
                    <br />
                    <h3 className="text-lg px-4 leading-6 font-large">Additional Conditions</h3>
                    <AdditionalConditionsQuote
                      id="terms"
                      type="text"
                      handleChange={handleChange}
                      value={values.terms}
                      sharedCheckBoxesState={sharedCheckBoxesState}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="w-1/2">
                    <br />
                    <br />
                    <h3 className="text-lg px-4 leading-6 font-large">
                      Select Additional Conditions
                    </h3>
                    <br />
                    <TickBox
                      sharedCheckBoxesState={sharedCheckBoxesState}
                      setsharedCheckBoxesState={setsharedCheckBoxesState}
                      setFieldValue={setFieldValue}
                      value={values.terms}
                    />
                  </div>
                </div>
              )}
              {/* <h3 className="block text-sm font-medium text-gray-700 w-1/2 ml-4 mt-1">

              <h3 className="block text-sm font-medium text-gray-700 w-1/2 ml-4 mt-1">

                Terms And Conditions
              </h3>
              <div className="w-1/2 ml-4 mt-1 grid grid-cols-3 gap-x-0.5">
                <div>
                  <RadioButton
                    inputId="termsWay100"
                    name="termsWay100"
                    value="100"
                    onChange={(e) => {
                      setFieldValue("termsWay", e.target.value);
                      setTermsWay(e.target.value);
                      setFieldValue("terms", quoteTerms100);
                    }}
                    checked={values.termsWay === "100"}
                  />
                  <label htmlFor="termsWay" className="p-radiobutton-label ml-1">
                    100
                  </label>
                </div>

                <div>
                  <RadioButton
                    inputId="termsWay50"
                    name="termsWay50"
                    value="50"
                    onChange={(e) => {
                      setFieldValue("termsWay", e.target.value);
                      setTermsWay(e.target.value);
                      setFieldValue("terms", quoteTerms50);
                    }}
                    checked={values.termsWay === "50"}
                  />
                  <label htmlFor="termsWay50" className="p-radiobutton-label ml-1">
                    50
                  </label>
                </div>

                <div>
                  <RadioButton
                    inputId="termsWay10"
                    name="termsWay10"
                    value="10"
                    onChange={(e) => {
                      setFieldValue("termsWay", e.target.value);
                      setTermsWay(e.target.value);
                      setFieldValue("terms", quoteTerms10);
                    }}
                    checked={values.termsWay === "10"}
                  />
                  <label htmlFor="termsWay10" className="p-radiobutton-label ml-1">
                    10
                  </label>
                </div>

              </div> */}
              {/* {termsWay && (

              </div>
              {termsWay && (

                <div className="w-3/6">
                  <TextArea
                    title="Additional Conditions"
                    id="terms"
                    type="text"
                    handleChange={handleChange}
                    value={values.terms}
                    rows={10}
                  />
                </div>

              )} */}

              {/* <Totals
                weekTotal={weekTotal}
                total={total}
                erectDismantleTotal={erectDismantleTotal}
                additionalTotal={additionalTotal}
              /> */}
            </div>
          )}
        </Formik>
      </div>
      <div className="pl-4 mt-6">
        {formType === "edit" ? (
          <div className="flex space-x-4">
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && !redirectOutput}
              size="sm"
            >
              Save & Exit
            </Button>
            <Button
              type="submit"
              form="quoteForm"
              isLoading={quoteCreating && redirectOutput}
              size="sm"
              onClick={() => setRedirectOutput(true)}
            >
              Save & View
            </Button>
          </div>
        ) : (
          <Button type="submit" form="quoteForm" isLoading={quoteCreating} size="sm">
            Create Quote
          </Button>
        )}
      </div>
      <ClientForm
        heading="Create Client"
        open={clientForm}
        setOpen={setClientForm}
        setClientId={setClientId}
      />
      <ContactForm
        heading="Create Contact"
        open={contactForm}
        setOpen={setContactForm}
        setContactId={setContactId}
      />
    </div>
  );
}

function formatRateText(rates) {
  // console.log("RATES >>> ", rates);
  return `

  `;
}

function quoteLinesToJobTasks(lines, jobId, type, quoteId, PO_Number) {
  if (!type) {
    type = "New";
  }
  // console.log("lines", lines);
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    linesResult.push({
      job_id: jobId,
      task_type: type,
      zone: lines[i].zone,
      zone_label: lines[i].zone_label,
      type: lines[i].type,
      description: lines[i].description,
      total_hours: quantity,
      variation_quote_id: quoteId,
      PO_Number,
    });
  }
  return linesResult;
}
function quoteAddonsToEdInvoices(quote_addons, job_id) {
  return quote_addons.map((row) => {
    const invoice = {
      zone: "",
      zone_label: "",
      type: row?.type,
      description: row?.description,
      erect_percent: 0,
      erect: 0,
      dismantle_percent: 0,
      dismantle: 0,
      ed_total: row?.total || 0,
      complete_percent: 100,
      invoiced: row?.total || 0,
      balance: row?.total || 0,
      last_invoice: 0,
      status: "Pending",
      job_id,
    };
    return invoice;
  });
}

function quoteLinesToWeeklyInvoices(lines, jobId, taskCreated) {
  // console.log("lines", lines);
  let iteratorTask = 0;
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    linesResult.push({
      job_id: jobId,
      zone: lines[i].zone,
      zone_label: lines[i].zone_label,
      type: lines[i].type,
      description: lines[i].description,
      weekly_hire_rate: lines[i].weekly_fee,
      task_id: taskCreated[iteratorTask]?.id || 0,
    });
    iteratorTask++;
  }
  return linesResult;
}
function quoteLinesToEDInvoices(lines, jobId, taskCreated, quote_num) {
  // console.log("lines", lines);
  let iteratorTask = 0;
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    linesResult.push({
      job_id: jobId,
      task_id: taskCreated[iteratorTask]?.id || 0,
      PO_Number: taskCreated[iteratorTask]?.PO_Number || 0,
      Quote_Number: quote_num,
      zone: lines[i].zone,
      zone_label: lines[i].zone_label,
      type: lines[i].type,
      description: lines[i].description,
      erect_percent: 0,
      dismantle_percent: 0,
      complete_percent: 0,
      erect: lines[i].erect_dismantle * 0.7,
      dismantle: lines[i].erect_dismantle * 0.3,
      invoiced: 0,
      balance: 0,
      ed_total: lines[i].erect_dismantle,
    });
    iteratorTask++;
  }
  return linesResult;
}
